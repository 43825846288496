import { useRecoilValue } from 'recoil';
import i18next from "i18next";
import $ from "jquery";
import { Link } from "react-router-dom"
import constant from "../../constant"
import { getStorage } from '../../utils';
import { activeBranchListAtom } from '../../atom/GHGAtom';
import { useEffect } from 'react';

function Sidebar() {
    const activeBranchId = useRecoilValue(activeBranchListAtom);
    const branches = getStorage(constant.key.permission);


    let pm: any = [];
    if (activeBranchId !== "") {
        const branchDetail = branches.find((i: any) => i.branch_id === parseFloat(activeBranchId));
        pm = branchDetail.permission_ids.split(',');
    }

    useEffect(() => {
        addActiveClass();

        const scriptDefaultMenu = document.createElement('script');
        scriptDefaultMenu.src = "/assets/js/defaultmenu.min.js";
        scriptDefaultMenu.async = false;
        document.body.appendChild(scriptDefaultMenu);

        const scriptSimpleBarLib = document.createElement('script');
        scriptSimpleBarLib.src = "/assets/libs/simplebar/simplebar.min.js";
        scriptSimpleBarLib.async = false;
        document.body.appendChild(scriptSimpleBarLib);

        const scriptSimpleBar = document.createElement('script');
        scriptSimpleBar.src = "/assets/js/simplebar.js";
        scriptSimpleBar.async = false;
        document.body.appendChild(scriptSimpleBar);

        return () => {
            document.body.removeChild(scriptDefaultMenu);
            document.body.removeChild(scriptSimpleBarLib);
            document.body.removeChild(scriptSimpleBar);
        }
    }, [])

    function addActiveClass() {
        $('.side-menu__item').click((e: any) => {
            $(".side-menu__item").removeClass("active");
            $(e.currentTarget).addClass("active");
        });
    }

    return (
        <aside className="app-sidebar sticky" id="sidebar">
            <div className="main-sidebar-header">
                <Link to={constant.component.welcome.url} className="header-logo">
                    <img src="/assets/images/brand-logos/desktop-logo.png" alt="logo" className="desktop-logo" />
                    <img src="/assets/images/brand-logos/toggle-logo.png" alt="logo" className="toggle-logo" />
                    <img src="/assets/images/logo.png" alt="logo" className="desktop-dark" />
                    <img src="/assets/images/brand-logos/toggle-dark.png" alt="logo" className="toggle-dark" />
                    <img src="/assets/images/brand-logos/desktop-white.png" alt="logo" className="desktop-white" />
                    <img src="/assets/images/brand-logos/toggle-white.png" alt="logo" className="toggle-white" />
                </Link>
            </div>

            <div className="main-sidebar" id="sidebar-scroll">
                <nav className="main-menu-container nav nav-pills flex-column sub-open">
                    <div className="slide-left" id="slide-left">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24" viewBox="0 0 24 24"> <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"></path> </svg>
                    </div>
                    <ul className="main-menu">
                        {renderESGAccounting()}
                        {renderGHGAccounting()}
                        {renderDecarbonization()}
                        {renderOffsetEmissions()}
                        {renderComplianceReporting()}
                        {renderSystemConfiguration()}
                    </ul>
                    <div className="slide-right" id="slide-right"><svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24" viewBox="0 0 24 24"> <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path> </svg></div>
                </nav>
            </div>
        </aside>
    )

    function renderESGAccounting() {
        const viewDashboard = pm.filter((i: any) => i === constant.permission.esgViewDashboard.toString()).length > 0
        const captureEnvironmentData = pm.filter((i: any) => i === constant.permission.esgCaptureEnvironmentData.toString()).length > 0
        const captureGovernanceData = pm.filter((i: any) => i === constant.permission.esgCaptureGovernanceData.toString()).length > 0
        const captureSocialData = pm.filter((i: any) => i === constant.permission.esgCaptureSocialData.toString()).length > 0
        const materialityConfig = pm.filter((i: any) => i === constant.permission.esgMaterialityConfig.toString()).length > 0

        if (!viewDashboard && !captureEnvironmentData && !captureGovernanceData && !captureSocialData && !materialityConfig) {
            return null;
        }

        return (
            <>
                <li className="slide__category"><span className="category-name">{i18next.t("sidebar.esg")}</span></li>
                <li className="slide has-sub">
                    <a href="javascript:void(0);" className="side-menu__item">
                        <i className="ti ti-chart-bar side-menu__icon"></i>
                        <span className="side-menu__label">{i18next.t("sidebar.dashboard")}</span>
                        <i className="ti ti-chevron-right side-menu__angle"></i>
                    </a>
                    <ul className="slide-menu child1">
                        <li className="slide side-menu__label1">
                            <a href="javascript:void(0)">{i18next.t("sidebar.dashboard")}</a>
                        </li>
                        <li className="slide has-sub">
                            <a href="javascript:void(0);" className="side-menu__item">Environment<i className="ti ti-chevron-right side-menu__angle"></i></a>
                            <ul className="slide-menu child2">
                                <li className="slide">
                                    <Link to={constant.component.esgEnergyDashboard.url} className="side-menu__item" data-bs-toggle="tooltip" title="Energy Analytics">Energy</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgWaterDashboard.url} className="side-menu__item" data-bs-toggle="tooltip" title="Water Analytics">Water</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgWasteDashboard.url} className="side-menu__item" data-bs-toggle="tooltip" title="Waste Analytics">Waste</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.comingSoon.url} className="side-menu__item" data-bs-toggle="tooltip" title="Emission Analytics">Emissions</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.comingSoon.url} className="side-menu__item" data-bs-toggle="tooltip" title="BioDiversity Analytics">BioDiversity</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="slide has-sub">
                            <a href="javascript:void(0);" className="side-menu__item">Social<i className="ti ti-chevron-right side-menu__angle"></i></a>
                            <ul className="slide-menu child2">
                                <li className="slide">
                                    <Link to={constant.component.esgSocialDiversityDashboard.url} className="side-menu__item" data-bs-toggle="tooltip" title="Diversity Analytics">Diversity</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgSocialEmployementDashboard.url} className="side-menu__item" data-bs-toggle="tooltip" title="Employment Analytics">Employment</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgSocialOccupationalDashboard.url} className="side-menu__item" data-bs-toggle="tooltip" title="Occupational Health & Safety">Occupational H&S</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.comingSoon.url} className="side-menu__item" data-bs-toggle="tooltip" title="Training Analytics">Training</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.comingSoon.url} className="side-menu__item" data-bs-toggle="tooltip" title="Incidents Analytics">Incidents</Link>
                                </li>

                            </ul>
                        </li>
                        <li className="slide has-sub">
                            <a href="javascript:void(0);" className="side-menu__item">Governance<i className="ti ti-chevron-right side-menu__angle"></i></a>
                            <ul className="slide-menu child2">
                                <li className="slide">
                                    <Link to={constant.component.esgGovernanceSummaryDashboard.url} className="side-menu__item" data-bs-toggle="tooltip" title="Purchased Good and Services">Summary</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.comingSoon.url} className="side-menu__item" data-bs-toggle="tooltip" title="Capital Goods">Performance</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.comingSoon.url} className="side-menu__item" data-bs-toggle="tooltip" title="Capital Goods">Corruption</Link>
                                </li>
                            </ul>
                        </li>
                    </ul>

                </li>
                <li className="slide">
                    <Link to={constant.component.esgCapture.url} className="side-menu__item" title={i18next.t('sidebar.action_library')}>
                        <i className="ti ti-pencil-search side-menu__icon"></i>
                        <span className="side-menu__label">{i18next.t('sidebar.capture_esg_data')}</span>
                    </Link>
                </li>
                {/* <li className="slide has-sub">
                    <a href="javascript:void(0);" className="side-menu__item">
                        <i className="ti ti-pencil-search side-menu__icon"></i>
                        <span className="side-menu__label">{i18next.t("sidebar.capture_esg_data")}</span>
                        <i className="ti ti-chevron-right side-menu__angle"></i>
                    </a>
                    <ul className="slide-menu child1">
                        <li className="slide side-menu__label1">
                            <a href="javascript:void(0)">Capture ESG Data</a>
                        </li>
                        <li className="slide has-sub">
                            <a href="javascript:void(0);" className="side-menu__item">Environment<i className="ti ti-chevron-right side-menu__angle"></i></a>
                            <ul className="slide-menu child2">
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '1')} className="side-menu__item" data-bs-toggle="tooltip" title="Biodiversity 2024">Biodiversity 2024</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '2')} className="side-menu__item" data-bs-toggle="tooltip" title="Material 2016">Material</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '3')} className="side-menu__item" data-bs-toggle="tooltip" title="Energy 2016">Energy</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '4')} className="side-menu__item" data-bs-toggle="tooltip" title="Water & Effulents 2018">Water & Effulents</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '5')} className="side-menu__item" data-bs-toggle="tooltip" title="Biodiversity 2016">Biodiversity 2016</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '6')} className="side-menu__item" data-bs-toggle="tooltip" title="Emissions 2016">Emissions</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '7')} className="side-menu__item" data-bs-toggle="tooltip" title="Effuents and waste 2016">Effuents & waste</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '8')} className="side-menu__item" data-bs-toggle="tooltip" title="Waste 2020">Waste</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '9')} className="side-menu__item" data-bs-toggle="tooltip" title="Supplier Environmental Assessment 2016">Supplier Env. Assess.</Link>
                                </li>

                            </ul>
                        </li>
                        <li className="slide has-sub">
                            <a href="javascript:void(0);" className="side-menu__item">Social<i className="ti ti-chevron-right side-menu__angle"></i></a>
                            <ul className="slide-menu child2">
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '10')} className="side-menu__item" data-bs-toggle="tooltip" title="Biodiversity 2024">Employment</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '11')} className="side-menu__item" data-bs-toggle="tooltip" title="Labor/Management Relations">Labor/Mgmt Relation</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '12')} className="side-menu__item" data-bs-toggle="tooltip" title="Occupational Health and Safety">Occupational H&S</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '13')} className="side-menu__item" data-bs-toggle="tooltip" title="Training & Education">Training & Education</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '14')} className="side-menu__item" data-bs-toggle="tooltip" title="Diversity & Equal Opportunity">Diversity</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '15')} className="side-menu__item" data-bs-toggle="tooltip" title="Non Discrimination">Non Discrimination</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '16')} className="side-menu__item" data-bs-toggle="tooltip" title="Freedom of Association and Collective Bargaining">Freedom of Assoc.</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '17')} className="side-menu__item" data-bs-toggle="tooltip" title="Child Labor">Child Labor</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '18')} className="side-menu__item" data-bs-toggle="tooltip" title="Focused or Compulsory Labor">Focused Labor</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '19')} className="side-menu__item" data-bs-toggle="tooltip" title="Security Practices">Security Practices</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '20')} className="side-menu__item" data-bs-toggle="tooltip" title="Right of Indigenous People">Right of People</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '21')} className="side-menu__item" data-bs-toggle="tooltip" title="Local Communities">Local Communities</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '22')} className="side-menu__item" data-bs-toggle="tooltip" title="Supplier Social Assessment">Supplier Assessment</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '23')} className="side-menu__item" data-bs-toggle="tooltip" title="Public Policy">Public Policy</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '24')} className="side-menu__item" data-bs-toggle="tooltip" title="Customer Health and Safety">Customer H&S</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '25')} className="side-menu__item" data-bs-toggle="tooltip" title="Marketing & Labeling">Marketing & Labeling</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '26')} className="side-menu__item" data-bs-toggle="tooltip" title="Customer Privacy">Customer Privacy</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="slide has-sub">
                            <a href="javascript:void(0);" className="side-menu__item">Governance<i className="ti ti-chevron-right side-menu__angle"></i></a>
                            <ul className="slide-menu child2">
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '27')} className="side-menu__item" data-bs-toggle="tooltip" title="Economic Performance">Eco. Performance</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '28')} className="side-menu__item" data-bs-toggle="tooltip" title="Market Presence">Market Presence</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '29')} className="side-menu__item" data-bs-toggle="tooltip" title="Indirect Economic Impacts">Indirect Eco. Impacts</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '30')} className="side-menu__item" data-bs-toggle="tooltip" title="Procurement Practices">Procurement Practice</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '31')} className="side-menu__item" data-bs-toggle="tooltip" title="Anti-Corruption">Anti-Corruption</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '32')} className="side-menu__item" data-bs-toggle="tooltip" title="Anti-Competitive Behavior">Anti-Competitive B.</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.esgDetail.url.replace(':id', '33')} className="side-menu__item" data-bs-toggle="tooltip" title="Tax">Tax</Link>
                                </li>
                            </ul>
                        </li>
                    </ul>

                </li> */}
                <li className="slide has-sub">
                    <a href="javascript:void(0);" className="side-menu__item">
                        <i className="ti ti-search side-menu__icon"></i>
                        <span className="side-menu__label">{i18next.t("sidebar.materiality_config")}</span>
                        <i className="ti ti-chevron-right side-menu__angle"></i>
                    </a>
                    <ul className="slide-menu child1">
                        <li className="slide">
                            <Link to={constant.component.esgMaterialConfigMaterialProfile.url} className="side-menu__item">Material Profile</Link>
                        </li>
                        <li className="slide">
                            <Link to={constant.component.esgMaterialConfigMaterialQuestion.url} className="side-menu__item">Material Question</Link>
                        </li>
                        {/* <li className="slide">
                            <Link to={constant.component.comingSoon.url} className="side-menu__item">Sector Configuration</Link>
                        </li> */}
                    </ul>
                </li>
            </>
        )
    }

    function renderGHGAccounting() {
        const ghgViewDashboard = pm.filter((i: any) => i === constant.permission.ghgViewDashboard.toString()).length > 0

        const ghgCaptureStationaryEmission = pm.filter((i: any) => i === constant.permission.ghgCaptureStationaryEmission.toString()).length > 0
        const ghgCaptureFugitiveEmission = pm.filter((i: any) => i === constant.permission.ghgCaptureFugitiveEmission.toString()).length > 0
        const ghgCaptureMobileEmission = pm.filter((i: any) => i === constant.permission.ghgCaptureMobileEmission.toString()).length > 0
        const ghgCapturePurchasedElectricityEmission = pm.filter((i: any) => i === constant.permission.ghgCapturePurchasedElectricityEmission.toString()).length > 0

        const ghgCaptureCategory1Emission = pm.filter((i: any) => i === constant.permission.ghgCaptureCategory1Emission.toString()).length > 0
        const ghgCaptureCategory2Emission = pm.filter((i: any) => i === constant.permission.ghgCaptureCategory2Emission.toString()).length > 0
        const ghgCaptureCategory4Emission = pm.filter((i: any) => i === constant.permission.ghgCaptureCategory4Emission.toString()).length > 0
        const ghgCaptureCategory5Emission = pm.filter((i: any) => i === constant.permission.ghgCaptureCategory5Emission.toString()).length > 0
        const ghgCaptureCategory6Emission = pm.filter((i: any) => i === constant.permission.ghgCaptureCategory6Emission.toString()).length > 0
        const ghgCaptureCategory7Emission = pm.filter((i: any) => i === constant.permission.ghgCaptureCategory7Emission.toString()).length > 0
        const ghgCaptureCategory8Emission = pm.filter((i: any) => i === constant.permission.ghgCaptureCategory8Emission.toString()).length > 0
        const ghgCaptureCategory11Emission = pm.filter((i: any) => i === constant.permission.ghgCaptureCategory11Emission.toString()).length > 0
        const ghgCaptureCategory12Emission = pm.filter((i: any) => i === constant.permission.ghgCaptureCategory12Emission.toString()).length > 0
        const ghgCaptureCategory13Emission = pm.filter((i: any) => i === constant.permission.ghgCaptureCategory13Emission.toString()).length > 0
        const ghgCaptureCategory14Emission = pm.filter((i: any) => i === constant.permission.ghgCaptureCategory14Emission.toString()).length > 0
        const ghgCaptureCategory15Emission = pm.filter((i: any) => i === constant.permission.ghgCaptureCategory15Emission.toString()).length > 0

        const ghgScope1CapturePermission = ghgCaptureStationaryEmission && ghgCaptureFugitiveEmission && ghgCaptureMobileEmission;
        const ghgScope2CapturePermission = ghgCapturePurchasedElectricityEmission;
        const ghgScope3CapturePermission = ghgCaptureCategory1Emission && ghgCaptureCategory2Emission && ghgCaptureCategory4Emission && ghgCaptureCategory5Emission && ghgCaptureCategory6Emission && ghgCaptureCategory7Emission && ghgCaptureCategory8Emission && ghgCaptureCategory11Emission && ghgCaptureCategory12Emission && ghgCaptureCategory13Emission && ghgCaptureCategory14Emission && ghgCaptureCategory15Emission;
        const ghgCapturePermission = ghgScope1CapturePermission || ghgScope2CapturePermission || ghgScope3CapturePermission;

        const ghgAuditStationaryEmission = pm.filter((i: any) => i === constant.permission.ghgAuditStationaryEmission.toString()).length > 0
        const ghgAuditFugitiveEmission = pm.filter((i: any) => i === constant.permission.ghgAuditFugitiveEmission.toString()).length > 0
        const ghgAuditMobileEmission = pm.filter((i: any) => i === constant.permission.ghgAuditMobileEmission.toString()).length > 0
        const ghgAuditPurchasedElectricityEmission = pm.filter((i: any) => i === constant.permission.ghgAuditPurchasedElectricityEmission.toString()).length > 0

        const ghgAuditCategory1Emission = pm.filter((i: any) => i === constant.permission.ghgAuditCategory1Emission.toString()).length > 0
        const ghgAuditCategory2Emission = pm.filter((i: any) => i === constant.permission.ghgAuditCategory2Emission.toString()).length > 0
        const ghgAuditCategory3Emission = pm.filter((i: any) => i === constant.permission.ghgAuditCategory3Emission.toString()).length > 0
        const ghgAuditCategory4Emission = pm.filter((i: any) => i === constant.permission.ghgAuditCategory4Emission.toString()).length > 0
        const ghgAuditCategory5Emission = pm.filter((i: any) => i === constant.permission.ghgAuditCategory5Emission.toString()).length > 0
        const ghgAuditCategory6Emission = pm.filter((i: any) => i === constant.permission.ghgAuditCategory6Emission.toString()).length > 0
        const ghgAuditCategory7Emission = pm.filter((i: any) => i === constant.permission.ghgAuditCategory7Emission.toString()).length > 0
        const ghgAuditCategory8Emission = pm.filter((i: any) => i === constant.permission.ghgAuditCategory8Emission.toString()).length > 0
        const ghgAuditCategory11Emission = pm.filter((i: any) => i === constant.permission.ghgAuditCategory11Emission.toString()).length > 0
        const ghgAuditCategory12Emission = pm.filter((i: any) => i === constant.permission.ghgAuditCategory12Emission.toString()).length > 0
        const ghgAuditCategory13Emission = pm.filter((i: any) => i === constant.permission.ghgAuditCategory13Emission.toString()).length > 0
        const ghgAuditCategory14Emission = pm.filter((i: any) => i === constant.permission.ghgAuditCategory14Emission.toString()).length > 0
        const ghgAuditCategory15Emission = pm.filter((i: any) => i === constant.permission.ghgAuditCategory15Emission.toString()).length > 0


        const ghgScope1AuditPermission = ghgAuditStationaryEmission && ghgAuditFugitiveEmission && ghgAuditMobileEmission;
        const ghgScope2AuditPermission = ghgAuditPurchasedElectricityEmission;
        const ghgScope3AuditPermission = ghgAuditCategory1Emission && ghgAuditCategory2Emission && ghgAuditCategory3Emission && ghgAuditCategory4Emission && ghgAuditCategory5Emission && ghgAuditCategory6Emission && ghgAuditCategory7Emission && ghgAuditCategory8Emission && ghgAuditCategory11Emission && ghgAuditCategory12Emission && ghgAuditCategory13Emission && ghgAuditCategory14Emission && ghgAuditCategory15Emission;
        const ghgAuditPermission = ghgScope1AuditPermission || ghgScope2AuditPermission || ghgScope3AuditPermission;

        if (!ghgViewDashboard && !ghgCapturePermission && !ghgAuditPermission) {
            return null;
        }

        return (
            <>
                {/* GHG Accounting Menu Start */}
                <li className="slide__category"><span className="category-name">{i18next.t("sidebar.ghg_accounting")}</span></li>
                {
                    ghgViewDashboard && <li className="slide has-sub">
                        <a href="javascript:void(0);" className="side-menu__item">
                            <i className="ti ti-chart-bar side-menu__icon"></i>
                            <span className="side-menu__label">{i18next.t("sidebar.dashboard")}</span>
                            <i className="ti ti-chevron-right side-menu__angle"></i>
                        </a>
                        <ul className="slide-menu child1">
                            <li className="slide side-menu__label1">
                                <a href="javascript:void(0)">{i18next.t("sidebar.dashboard")}</a>
                            </li>
                            <li className="slide">
                                <Link to={constant.component.ghgOrganisationEmissionDashboard.url} className="side-menu__item" title={i18next.t("sidebar.organisation_emission")}>
                                    {i18next.t("sidebar.organisation_emission")}
                                </Link>
                            </li>
                            <li className="slide">
                                <Link to={constant.component.ghgBranchEmissionDashboard.url} className="side-menu__item" title={i18next.t("sidebar.branch_emission")}>
                                    {i18next.t("sidebar.branch_emission")}
                                </Link>
                            </li>
                        </ul>
                    </li>
                }
                <li className="slide has-sub">
                    <a href="javascript:void(0);" className="side-menu__item">
                        <i className="ti ti-pencil-search side-menu__icon"></i>
                        <span className="side-menu__label">{i18next.t("sidebar.capture_activity")}</span>
                        <i className="ti ti-chevron-right side-menu__angle"></i>
                    </a>
                    <ul className="slide-menu child1">
                        <li className="slide side-menu__label1">
                            <a href="javascript:void(0)">{i18next.t("sidebar.capture_activity")}</a>
                        </li>
                        {
                            (ghgCaptureStationaryEmission || ghgAuditStationaryEmission ||
                                ghgCaptureFugitiveEmission || ghgAuditFugitiveEmission ||
                                ghgCaptureMobileEmission || ghgAuditMobileEmission) && <li className="slide has-sub">
                                <a href="javascript:void(0);" className="side-menu__item">{i18next.t('sidebar.scope_1')}<i className="ti ti-chevron-right side-menu__angle"></i></a>
                                <ul className="slide-menu child2">
                                    {
                                        (ghgCaptureStationaryEmission || ghgAuditStationaryEmission) && <li className="slide">
                                            <Link to={constant.component.ghgStationaryEmission.url} className="side-menu__item" title={i18next.t('sidebar.stationary_emission')}>
                                                {i18next.t('sidebar.stationary_emission')}
                                            </Link>
                                        </li>
                                    }

                                    {
                                        (ghgCaptureFugitiveEmission || ghgAuditFugitiveEmission) && <>
                                            <li className="slide has-sub">
                                                <a href="javascript:void(0);" className="side-menu__item">{i18next.t('sidebar.fugitive_emission')}<i className="ti ti-chevron-right side-menu__angle"></i></a>
                                                <ul className="slide-menu child3" style={{ display: "none", boxSizing: "border-box" }}>
                                                    <li className="slide">
                                                        <Link to={constant.component.ghgFugitive1Emission.url} className="side-menu__item" title={i18next.t('sidebar.fugitive_1_emission')}>
                                                            {i18next.t('sidebar.fugitive_1_emission')}
                                                        </Link>
                                                    </li>
                                                    <li className="slide">
                                                        <Link to={constant.component.ghgFugitive2Emission.url} className="side-menu__item" title={i18next.t('sidebar.fugitive_2_emission')}>
                                                            {i18next.t('sidebar.fugitive_2_emission')}
                                                        </Link>
                                                    </li>
                                                    <li className="slide">
                                                        <Link to={constant.component.ghgFugitive3Emission.url} className="side-menu__item" title={i18next.t('sidebar.fugitive_3_emission')}>
                                                            {i18next.t('sidebar.fugitive_3_emission')}
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </li>
                                        </>
                                    }
                                    {
                                        (ghgCaptureMobileEmission || ghgAuditMobileEmission) && <li className="slide">
                                            <Link to={constant.component.ghgMobileCombustion.url} className="side-menu__item" title={i18next.t('sidebar.mobile_combustion')}>
                                                {i18next.t('sidebar.mobile_combustion')}
                                            </Link>
                                        </li>
                                    }
                                </ul>
                            </li>
                        }
                        {
                            (ghgCapturePurchasedElectricityEmission || ghgAuditPurchasedElectricityEmission) && <li className="slide has-sub">
                                <a href="javascript:void(0);" className="side-menu__item">{i18next.t('sidebar.scope_2')}<i className="ti ti-chevron-right side-menu__angle"></i></a>
                                <ul className="slide-menu child2">
                                    <li className="slide">
                                        <Link to={constant.component.ghgPurchasedElectricity.url} className="side-menu__item" title={i18next.t('sidebar.purchased_electricity')}>
                                            {i18next.t('sidebar.purchased_electricity')}
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        }
                        {
                            (
                                ghgCaptureCategory1Emission ||
                                ghgCaptureCategory2Emission ||
                                ghgCaptureCategory4Emission ||
                                ghgCaptureCategory5Emission ||
                                ghgCaptureCategory6Emission ||
                                ghgCaptureCategory7Emission ||
                                ghgCaptureCategory8Emission ||
                                ghgCaptureCategory11Emission ||
                                ghgCaptureCategory12Emission ||
                                ghgCaptureCategory13Emission ||
                                ghgCaptureCategory14Emission ||
                                ghgCaptureCategory15Emission ||

                                ghgAuditCategory1Emission ||
                                ghgAuditCategory2Emission ||
                                ghgAuditCategory3Emission ||
                                ghgAuditCategory4Emission ||
                                ghgAuditCategory5Emission ||
                                ghgAuditCategory6Emission ||
                                ghgAuditCategory7Emission ||
                                ghgAuditCategory8Emission ||
                                ghgAuditCategory11Emission ||
                                ghgAuditCategory12Emission ||
                                ghgAuditCategory13Emission ||
                                ghgAuditCategory14Emission ||
                                ghgAuditCategory15Emission

                            ) && <li className="slide has-sub">
                                <a href="javascript:void(0);" className="side-menu__item">{i18next.t('sidebar.scope_3')}<i className="ti ti-chevron-right side-menu__angle"></i></a>
                                <ul className="slide-menu child2">
                                    {
                                        (ghgCaptureCategory1Emission || ghgAuditCategory1Emission) && <li className="slide">
                                            <Link to={constant.component.ghgCategory1Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title={i18next.t('sidebar.category_1')}>{i18next.t('sidebar.category_1')}</Link>
                                        </li>
                                    }
                                    {
                                        (ghgCaptureCategory2Emission || ghgAuditCategory2Emission) && <li className="slide">
                                            <Link to={constant.component.ghgCategory2Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title={i18next.t('sidebar.category_2')}>{i18next.t('sidebar.category_2')}</Link>
                                        </li>
                                    }
                                    {
                                        ghgAuditCategory3Emission && <li className="slide">
                                            <Link to={constant.component.ghgCategory3Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title={i18next.t('sidebar.category_3')}>{i18next.t('sidebar.category_3')}</Link>
                                        </li>
                                    }
                                    {
                                        (ghgCaptureCategory4Emission || ghgAuditCategory4Emission) && <li className="slide">
                                            <Link to={constant.component.ghgCategory4Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title={i18next.t('sidebar.category_4')}>{i18next.t('sidebar.category_4')}</Link>
                                        </li>
                                    }
                                    {
                                        (ghgCaptureCategory5Emission || ghgAuditCategory5Emission) && <li className="slide">
                                            <Link to={constant.component.ghgCategory5Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title={i18next.t('sidebar.category_5')}>{i18next.t('sidebar.category_5')}</Link>
                                        </li>
                                    }
                                    {
                                        (ghgCaptureCategory6Emission || ghgAuditCategory6Emission) && <>
                                            <li className="slide has-sub">
                                                <a href="javascript:void(0);" className="side-menu__item">{i18next.t('sidebar.category_6')}<i className="ti ti-chevron-right side-menu__angle"></i></a>
                                                <ul className="slide-menu child3" style={{ display: "none", boxSizing: "border-box" }}>
                                                    <li className="slide"><Link to={constant.component.ghgCategory6FlightEmission.url} className="side-menu__item" title={i18next.t('sidebar.category_6_flight')}>{i18next.t('sidebar.category_6_flight')}</Link></li>
                                                    <li className="slide"><Link to={constant.component.ghgCategory6RailEmission.url} className="side-menu__item" title={i18next.t('sidebar.category_6_rail')}>{i18next.t('sidebar.category_6_rail')}</Link></li>
                                                    <li className="slide"><Link to={constant.component.ghgCategory6CarEmission.url} className="side-menu__item" title={i18next.t('sidebar.category_6_car')}>{i18next.t('sidebar.category_6_car')}</Link></li>
                                                    <li className="slide"><Link to={constant.component.ghgCategory6HotelEmission.url} className="side-menu__item" title={i18next.t('sidebar.category_6_hotel')}>{i18next.t('sidebar.category_6_hotel')}</Link></li>
                                                </ul>
                                            </li>
                                        </>
                                    }
                                    {
                                        (ghgCaptureCategory7Emission || ghgAuditCategory7Emission) && <li className="slide">
                                            <Link to={constant.component.ghgCategory7Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title={i18next.t('sidebar.category_7')}>{i18next.t('sidebar.category_7')}</Link>
                                        </li>
                                    }
                                    {
                                        (ghgCaptureCategory8Emission || ghgAuditCategory8Emission) && <li className="slide">
                                            <Link to={constant.component.ghgCategory8Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title={i18next.t('sidebar.category_8')}>{i18next.t('sidebar.category_8')}</Link>
                                        </li>
                                    }
                                    {
                                        (ghgCaptureCategory8Emission || ghgAuditCategory8Emission) && <li className="slide">
                                            <Link to={constant.component.comingSoon.url} className="side-menu__item" data-bs-toggle="tooltip" title={i18next.t('sidebar.category_9')}>{i18next.t('sidebar.category_9')}</Link>
                                        </li>
                                    }
                                    {
                                        (ghgCaptureCategory8Emission || ghgAuditCategory8Emission) && <li className="slide">
                                            <Link to={constant.component.comingSoon.url} className="side-menu__item" data-bs-toggle="tooltip" title={i18next.t('sidebar.category_10')}>{i18next.t('sidebar.category_10')}</Link>
                                        </li>
                                    }
                                    {
                                        (ghgCaptureCategory11Emission || ghgAuditCategory11Emission) && <li className="slide">
                                            <Link to={constant.component.ghgCategory11Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title={i18next.t('sidebar.category_11')}>{i18next.t('sidebar.category_11')}</Link>
                                        </li>
                                    }
                                    {
                                        (ghgCaptureCategory12Emission || ghgAuditCategory12Emission) && <li className="slide">
                                            <Link to={constant.component.ghgCategory12Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title={i18next.t('sidebar.category_12')}>{i18next.t('sidebar.category_12')}</Link>
                                        </li>
                                    }
                                    {
                                        (ghgCaptureCategory13Emission || ghgAuditCategory13Emission) && <li className="slide">
                                            <Link to={constant.component.ghgCategory13Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title={i18next.t('sidebar.category_13')}>{i18next.t('sidebar.category_13')}</Link>
                                        </li>
                                    }
                                    {
                                        (ghgCaptureCategory14Emission || ghgAuditCategory14Emission) && <li className="slide">
                                            <Link to={constant.component.ghgCategory14Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title={i18next.t('sidebar.category_14')}>{i18next.t('sidebar.category_14')}</Link>
                                        </li>
                                    }
                                    {
                                        (ghgCaptureCategory15Emission || ghgAuditCategory15Emission) && <li className="slide">
                                            <Link to={constant.component.ghgCategory15Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title={i18next.t('sidebar.category_15')}>{i18next.t('sidebar.category_15')}</Link>
                                        </li>
                                    }
                                </ul>
                            </li>
                        }
                    </ul >
                </li >
            </>
        )
    }

    function renderDecarbonization() {
        const actionLibrary = pm.filter((i: any) => i === constant.permission.dcActionLibrary.toString()).length > 0
        const netZeroRoadmap = pm.filter((i: any) => i === constant.permission.dcNetZeroRoadmap.toString()).length > 0
        const netZeroGoalTracker = pm.filter((i: any) => i === constant.permission.dcNetZeroGoalTracker.toString()).length > 0

        if (!actionLibrary && !netZeroRoadmap && !netZeroGoalTracker) {
            return null;
        }

        return (
            <>
                <li className="slide__category"><span className="category-name">{i18next.t('sidebar.decarbonization')}</span></li>
                <li className="slide">
                    <Link to={constant.component.decarbonizationActionLibrary.url} className="side-menu__item" title={i18next.t('sidebar.action_library')}>
                        <i className="ti ti-book side-menu__icon"></i>
                        <span className="side-menu__label">{i18next.t('sidebar.action_library')}</span>
                    </Link>
                </li>
                <li className="slide">
                    <Link to={constant.component.decarbonizationNetZeroRoadMap.url} className="side-menu__item" title={i18next.t('sidebar.netzero_roadmap')}>
                        <i className="ti ti-route-square side-menu__icon"></i>
                        <span className="side-menu__label">{i18next.t('sidebar.netzero_roadmap')}</span>
                    </Link>
                </li>
                <li className="slide">
                    <Link to={constant.component.decarbonizationNetZeroGoalTracker.url} className="side-menu__item" title={i18next.t('sidebar.netzero_goal_tracker')}>
                        <i className="ti ti-target side-menu__icon"></i>
                        <span className="side-menu__label">{i18next.t('sidebar.netzero_goal_tracker')}</span>
                    </Link>
                </li>
            </>
        )
    }

    function renderOffsetEmissions() {
        const oeMarketplace = pm.filter((i: any) => i === constant.permission.oeMarketplace.toString()).length > 0
        const oeFavouriteProject = pm.filter((i: any) => i === constant.permission.oeFavouriteProject.toString()).length > 0
        const oePurchaseHistory = pm.filter((i: any) => i === constant.permission.oePurchaseHistory.toString()).length > 0

        if (!oeMarketplace && !oeFavouriteProject && !oePurchaseHistory) {
            return null;
        }

        return (
            <>
                <li className="slide__category"><span className="category-name">{i18next.t('sidebar.offset_emissions')}</span></li>
                <li className="slide">
                    <Link to={constant.component.offsetMarketPlace.url} className="side-menu__item" title={i18next.t('sidebar.marketplace')}>
                        <i className="ti ti-shopping-cart side-menu__icon"></i>
                        <span className="side-menu__label">{i18next.t('sidebar.marketplace')}</span>
                    </Link>
                </li>
                <li className="slide">
                    <Link to={constant.component.offsetFavourite.url} className="side-menu__item" title={i18next.t('sidebar.favourite_projects')}>
                        <i className="ti ti-bookmarks side-menu__icon"></i>
                        <span className="side-menu__label">{i18next.t('sidebar.favourite_projects')}</span>
                    </Link>
                </li>
                <li className="slide">
                    <Link to={constant.component.offsetPurchaseHistory.url} className="side-menu__item" title={i18next.t('sidebar.purchase_history')}>
                        <i className="ti ti-receipt side-menu__icon"></i>
                        <span className="side-menu__label">{i18next.t('sidebar.purchase_history')}</span>
                    </Link>
                </li>
            </>
        )
    }

    function renderComplianceReporting() {
        const crGHGReport = pm.filter((i: any) => i === constant.permission.crGHGReport.toString()).length > 0
        const crBRSRReport = pm.filter((i: any) => i === constant.permission.crBRSRReport.toString()).length > 0
        const crGRIReport = pm.filter((i: any) => i === constant.permission.crGRIReport.toString()).length > 0

        if (!crGHGReport && !crBRSRReport && !crGRIReport) {
            return null;
        }

        return (
            <>
                <li className="slide__category"><span className="category-name">{i18next.t('sidebar.compliance_reporting')}</span></li>
                <li className="slide">
                    <Link to={constant.component.comingSoon.url} className="side-menu__item" title={i18next.t('sidebar.ghg_report')}>
                        <i className="ti ti-report side-menu__icon"></i>
                        <span className="side-menu__label">{i18next.t('sidebar.ghg_report')}</span>
                    </Link>
                </li>
                <li className="slide">
                    <Link to={constant.component.brsReport.url} className="side-menu__item" title={i18next.t('sidebar.brsr')}>
                        <i className="ti ti-report side-menu__icon"></i>
                        <span className="side-menu__label">{i18next.t('sidebar.brsr')}</span>
                    </Link>
                </li>
                <li className="slide">
                    <Link to={constant.component.complianceGRI.url} className="side-menu__item" title={i18next.t('sidebar.gri')}>
                        <i className="ti ti-report side-menu__icon"></i>
                        <span className="side-menu__label">{i18next.t('sidebar.gri')}</span>
                    </Link>
                </li>
            </>
        )
    }

    function renderSystemConfiguration() {
        const manageGreenAPI = pm.filter((i: any) => i === constant.permission.scManageGreenAPI.toString()).length > 0;
        const manageUserManagement = pm.filter((i: any) => i === constant.permission.scManageUserManagement.toString()).length > 0;
        const manageLocationManagement = pm.filter((i: any) => i === constant.permission.scManageLocationManagement.toString()).length > 0;
        const manageMasterData = pm.filter((i: any) => i === constant.permission.scManageMasterData.toString()).length > 0;
        const manageEmissionFactor = pm.filter((i: any) => i === constant.permission.scManageEmissionFactor.toString()).length > 0;
        const manageGeneralSettings = pm.filter((i: any) => i === constant.permission.scManageGeneralSettings.toString()).length > 0;

        if (!manageGreenAPI && !manageUserManagement && !manageLocationManagement && !manageMasterData && !manageEmissionFactor && !manageGeneralSettings) {
            return null;
        }
        return (
            <>
                <li className="slide__category"><span className="category-name">{i18next.t('sidebar.system_configuration')}</span></li>
                {
                    manageGreenAPI && <li className="slide has-sub">
                        <a href="javascript:void(0);" className="side-menu__item">
                            <i className="ti ti-leaf side-menu__icon"></i>
                            <span className="side-menu__label">{i18next.t('sidebar.green_api')}</span>
                            <i className="ti ti-chevron-right side-menu__angle"></i>
                        </a>
                        <ul className="slide-menu child1">
                            <li className="slide has-sub">
                                <a href="javascript:void(0);" className="side-menu__item">Master Data<i className="ti ti-chevron-right side-menu__angle"></i></a>
                                <ul className="slide-menu child2">
                                    <li className="slide">
                                        <Link to={constant.component.greenAPIMasterDataFuelType.url} className="side-menu__item">Fuel Type</Link>
                                    </li>
                                    <li className="slide">
                                        <Link to={constant.component.greenAPIMasterDataGasType.url} className="side-menu__item">Gas Type</Link>
                                    </li>
                                    <li className="slide">
                                        <Link to={constant.component.greenAPIMasterDataWasteType.url} className="side-menu__item">Waste Type</Link>
                                    </li>
                                    <li className="slide">
                                        <Link to={constant.component.greenAPIMasterDataTransportation.url} className="side-menu__item">Transportation</Link>
                                    </li>
                                    <li className="slide">
                                        <Link to={constant.component.greenAPIMasterDataElectricity.url} className="side-menu__item">Electricity</Link>
                                    </li>
                                    <li className="slide">
                                        <Link to={constant.component.greenAPIMasterDataHotel.url} className="side-menu__item">Hotel</Link>
                                    </li>
                                    <li className="slide">
                                        <Link to={constant.component.greenAPIMasterDataGoodsAndServices.url} className="side-menu__item">Goods & Services</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="slide has-sub">
                                <a href="javascript:void(0);" className="side-menu__item">Emission Factor<i className="ti ti-chevron-right side-menu__angle"></i></a>
                                <ul className="slide-menu child2">
                                    <li className="slide">
                                        <Link to={constant.component.greenAPIEmissionFactorFuelType.url} className="side-menu__item">Fuel Type</Link>
                                    </li>
                                    <li className="slide">
                                        <Link to={constant.component.greenAPIEmissionFactorGasType.url} className="side-menu__item">Gas Type</Link>
                                    </li>
                                    <li className="slide">
                                        <Link to={constant.component.greenAPIEmissionFactorWasteType.url} className="side-menu__item">Waste Type</Link>
                                    </li>
                                    <li className="slide">
                                        <Link to={constant.component.greenAPIEmissionFactorTransportation.url} className="side-menu__item">Transportation</Link>
                                    </li>
                                    <li className="slide">
                                        <Link to={constant.component.greenAPIEmissionFactorElectricity.url} className="side-menu__item">Electricity</Link>
                                    </li>
                                    <li className="slide">
                                        <Link to={constant.component.greenAPIEmissionFactorHotel.url} className="side-menu__item">Hotel</Link>
                                    </li>
                                    <li className="slide">
                                        <Link to={constant.component.greenAPIEmissionFactorGoodsAndServices.url} className="side-menu__item">Goods & Services</Link>
                                    </li>
                                </ul>
                            </li>
                            {/* <li className="slide">
                                <Link to="/settings/green-api/master-data" className="side-menu__item">{i18next.t('sidebar.master_data')}</Link>
                            </li>
                            <li className="slide">
                                <Link to="/settings/green-api/emission-factor" className="side-menu__item">{i18next.t('sidebar.emission_factor')}</Link>
                            </li> */}
                        </ul>
                    </li>
                }

                {
                    manageUserManagement && <li className="slide has-sub">
                        <a href="javascript:void(0);" className="side-menu__item">
                            <i className="ti ti-users side-menu__icon"></i>
                            <span className="side-menu__label">{i18next.t('sidebar.user_management')}</span>
                            <i className="ti ti-chevron-right side-menu__angle"></i>
                        </a>
                        <ul className="slide-menu child1">
                            <li className="slide">
                                <Link to={constant.component.manageRole.url} className="side-menu__item">{i18next.t('sidebar.manage_role')}</Link>
                            </li>
                            <li className="slide">
                                <Link to={constant.component.manageUser.url} className="side-menu__item">{i18next.t('sidebar.manage_user')}</Link>
                            </li>
                            <li className="slide">
                                <Link to={constant.component.manageCompanyBranch.url} className="side-menu__item">{i18next.t('sidebar.manage_branch')}</Link>
                            </li>
                        </ul>
                    </li>
                }
                {
                    manageLocationManagement && <li className="slide has-sub">
                        <a href="javascript:void(0);" className="side-menu__item">
                            <i className="ti ti-file-settings side-menu__icon"></i>
                            <span className="side-menu__label">{i18next.t('sidebar.configuration')}</span>
                            <i className="ti ti-chevron-right side-menu__angle"></i>
                        </a>
                        <ul className="slide-menu child1">
                            <li className="slide">
                                <Link to={constant.component.settingsConfigPhysicalLocation.url} className="side-menu__item">{i18next.t('sidebar.physical_location')}</Link>
                            </li>
                            <li className="slide">
                                <Link to={constant.component.settingsConfigReportingLocation.url} className="side-menu__item">{i18next.t('sidebar.reporting_unit')}</Link>
                            </li>
                            <li className="slide">
                                <Link to={constant.component.settingsConfigSupplier.url} className="side-menu__item">{i18next.t('sidebar.supplier')}</Link>
                            </li>
                        </ul>
                    </li>
                }

                {
                    manageMasterData && <li className="slide has-sub">
                        <a href="javascript:void(0);" className="side-menu__item">
                            <i className="ti ti-test-pipe side-menu__icon"></i>
                            <span className="side-menu__label">{i18next.t('sidebar.master_data')}</span>
                            <i className="ti ti-chevron-right side-menu__angle"></i>
                        </a>
                        <ul className="slide-menu child1">

                            <li className="slide">
                                <Link to={constant.component.settingsMasterDataFuelType.url} className="side-menu__item">{i18next.t('sidebar.fuel_type')}</Link>
                            </li>
                            <li className="slide">
                                <Link to={constant.component.settingsMasterDataGasType.url} className="side-menu__item">{i18next.t('sidebar.gas_type')}</Link>
                            </li>
                            <li className="slide">
                                <Link to={constant.component.settingsMasterDataWasteType.url} className="side-menu__item">{i18next.t('sidebar.waste_type')}</Link>
                            </li>
                            <li className="slide">
                                <Link to={constant.component.settingsMasterDataGoodsAndServices.url} className="side-menu__item">{i18next.t('sidebar.goods_and_services')}</Link>
                            </li>
                            <li className="slide">
                                <Link to={constant.component.settingsMasterDataElectricity.url} className="side-menu__item">{i18next.t('sidebar.electricity')}</Link>
                            </li>
                            <li className="slide">
                                <Link to={constant.component.settingsMasterDataHotel.url} className="side-menu__item">{i18next.t('sidebar.hotel')}</Link>
                            </li>
                            <li className="slide">
                                <Link to={constant.component.settingsMasterDataTransportation.url} className="side-menu__item">{i18next.t('sidebar.transportation')}</Link>
                            </li>

                        </ul>
                    </li>
                }
                {
                    manageMasterData && <li className="slide has-sub">
                        <a href="javascript:void(0);" className="side-menu__item">
                            <i className="ti ti-campfire side-menu__icon"></i>
                            <span className="side-menu__label">{i18next.t('sidebar.emission_source')}</span>
                            <i className="ti ti-chevron-right side-menu__angle"></i>
                        </a>
                        <ul className="slide-menu child1">

                            <li className="slide">
                                <Link to={constant.component.settingsEmissionSourceScope1.url} className="side-menu__item">{i18next.t('sidebar.scope_1')}</Link>
                            </li>
                            <li className="slide">
                                <Link to={constant.component.settingsEmissionSourceScope2.url} className="side-menu__item">{i18next.t('sidebar.scope_2')}</Link>
                            </li>
                            <li className="slide">
                                <Link to={constant.component.settingsEmissionSourceScope3.url} className="side-menu__item">{i18next.t('sidebar.scope_3')}</Link>
                            </li>
                        </ul>
                    </li>
                }
                {
                    manageEmissionFactor && <li className="slide has-sub">
                        <a href="javascript:void(0);" className="side-menu__item">
                            <i className="ti ti-numbers side-menu__icon"></i>
                            <span className="side-menu__label">{i18next.t('sidebar.emission_factor')}</span>
                            <i className="ti ti-chevron-right side-menu__angle"></i>
                        </a>
                        <ul className="slide-menu child1">
                            <li className="slide">
                                <Link to={constant.component.settingsEmissionFactorFuelType.url} className="side-menu__item" data-bs-toggle="tooltip" title="Fuel Types">{i18next.t('sidebar.fuel_type')}</Link>
                            </li>
                            <li className="slide">
                                <Link to={constant.component.settingsEmissionFactorGasType.url} className="side-menu__item">{i18next.t('sidebar.gas_type')}</Link>
                            </li>
                            <li className="slide">
                                <Link to={constant.component.settingsEmissionFactorWasteType.url} className="side-menu__item">{i18next.t('sidebar.waste_type')}</Link>
                            </li>
                            <li className="slide">
                                <Link to={constant.component.settingsEmissionFactorElectricity.url} className="side-menu__item">{i18next.t('sidebar.electricity')}</Link>
                            </li>
                            <li className="slide">
                                <Link to={constant.component.settingsEmissionFactorTransportation.url} className="side-menu__item">{i18next.t('sidebar.transportation')}</Link>
                            </li>
                            <li className="slide">
                                <Link to={constant.component.settingsEmissionFactorGoodsAndServices.url} className="side-menu__item">{i18next.t('sidebar.goods_and_services')}</Link>
                            </li>
                            {/* <li className="slide">
                                <Link to={constant.component.settingsEmissionFactorCapitalGoods.url} className="side-menu__item">Capital Goods</Link>
                            </li> */}
                        </ul>
                    </li>
                }
                {
                    manageGeneralSettings && <li className="slide">
                        <Link to={constant.component.comingSoon.url} className="side-menu__item">
                            <i className="ti ti-settings side-menu__icon"></i>
                            <span className="side-menu__label">{i18next.t('sidebar.general_settings')}</span>
                        </Link>
                    </li>
                }

            </>
        )
    }
}

export default Sidebar